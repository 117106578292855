<template>
  <n-layout
    :content-style="this.isMobile ? 'padding: 8px; max-height: 90vh' : 'padding: 64px; max-height: 93vh'"
    :native-scrollbar="false"
  >

    <n-space vertical>
      <n-input-group>
        <n-input-group-label><n-icon><search-outline /></n-icon></n-input-group-label>
        <n-input v-model:value="searchField" type="text" placeholder="El. paštas"></n-input>
        <n-button type="primary" @click="doSearch()">Ieškoti</n-button>
      </n-input-group>

      <n-data-table
        remote
        :bordered="false"
        :loading="loading"
        :columns="columns"
        :data="data"
        :pagination="pagination"
        @update:page="handlePageChange"
      />
  </n-space>

    <n-modal v-model:show="showModal">
      <n-card
        style="width: 50em;"
        title="Vartotojo duomenys ir teisės"
        size="huge"
      >
        <!-- <template #header-extra> 📝 </template> -->
        <!-- {{ selectedUserData }} -->
        <n-space vertical>
          <n-input-group>
            <n-input-group-label :style="{ width: '20%' }">Vardas</n-input-group-label>
            <n-input v-model:value="selectedUserData.firstName" type="text" placeholder="vardas" />
          </n-input-group>
          <n-input-group>
            <n-input-group-label :style="{ width: '20%' }">Pavardė</n-input-group-label>
            <n-input v-model:value="selectedUserData.lastName" type="text" placeholder="pavardė" />
          </n-input-group>
          <n-input-group>
            <n-input-group-label :style="{ width: '20%' }">Įstaiga</n-input-group-label>
            <n-input v-model:value="selectedUserData.company" type="text" placeholder="įstaiga" />
          </n-input-group>
          <n-input-group>
            <n-input-group-label :style="{ width: '20%' }">El. paštas</n-input-group-label>
            <n-input v-model:value="selectedUserData.email" type="text" placeholder="email" />
          </n-input-group>
          <n-input-group>
            <n-input-group-label :style="{ width: '20%' }">Sukurta</n-input-group-label>
            <n-input v-model:value="selectedUserData.created" type="text" placeholder="laikas" :disabled="true"/>
          </n-input-group>
          <n-input-group>
            <n-input-group-label :style="{ width: '20%' }">Teisės</n-input-group-label>
            <n-select v-model:value="selectedUserData.role" :options="[{label: 'administatorius', value: 'Admin'}, {label: 'įprastas naudotojas', value: 'User'}]" />
          </n-input-group>
          <n-input-group>
            <n-input-group-label :style="{ width: '40%' }">Patvirtinta el. paštu</n-input-group-label>
            <n-select v-model:value="selectedUserData.isVerified" :value="boolean" :options="[{label: 'taip', value: 'true'}, {label: 'ne', value: 'false'}]"/>
          </n-input-group>
        </n-space>
        <template #action>
          <n-space>
            <n-button type="primary" @click='saveUserEdits()' :disabled="disableButton" :loading="disableButton">Išsaugoti</n-button>
            <n-popconfirm
              @positive-click="deleteUser()"
              positive-text="Taip"
              negative-text="Ne"
            >
              <template #trigger>
                <n-button type="warning" :disabled="disableButton">Pašalinti vartotoją</n-button>
              </template>
              Ar tikrai norite pašalinti vartotoją?
            </n-popconfirm>

          </n-space>
        </template>
      </n-card>
    </n-modal>

 </n-layout>
</template>

<script>

import { h, defineComponent, ref, onMounted, reactive } from 'vue'
import { NButton } from 'naive-ui'
import { useIsMobile } from '@/utils/composables'
import { SearchOutline  } from '@vicons/ionicons5'
import { useMessage } from 'naive-ui'

import jwtInterceptor from '../../shared/jwt.interceptor'
import config from '../../shared/config'

const getAccounts = async (options) => {
  const response = await jwtInterceptor
  .get(config.backend_server + "accounts/getAccounts/", { params: options }
  ).catch((error) => {
    console.log(error)
    return error.response
  })
  if (response && response.data && response.status === 200) {
    // console.log(response.data)
  } else {
    console.log('users: Not 200 from getAccounts')
  }
  return response
}

const updateAccount = async (id, params) => {
  const response = await jwtInterceptor
  .put(config.backend_server + "accounts/" + id, params
  ).catch((error) => {
    console.log(error)
    return error.response
  })
  if (response && response.data && response.status === 200) {
    // console.log(response.data)
  } else {
    console.log('users: Not 200 from updateAccount')
  }
  return response
}

const deleteAccount = async (id) => {
  const response = await jwtInterceptor
  .delete(config.backend_server + "accounts/" + id
  ).catch((error) => {
    console.log(error)
    return error.response
  })
  if (response && response.data && response.status === 200) {
    // console.log(response.data)
  } else {
    console.log('users: Not 200 from deleteAccount')
  }
  return response
}

const beforeEdit = (userData) => {
  userData.isVerified = userData.isVerified.toString()
  return userData
}

const afterEdit = (userData) => {
  let userData_ = Object.assign({}, userData);
  userData_.isVerified = (userData_.isVerified === 'true')
  return userData_
}

export default defineComponent({
  components: {
    SearchOutline
  },
  setup () {

    const message = useMessage()

    // data table
    const columns = [
      {
        title: 'Vardas',
        key: 'firstName',
      },
      {
        title: 'Pavardė',
        key: 'lastName',
      },
      {
        title: 'Įstaiga',
        key: 'company',
      },
      {
        title: 'El. paštas',
        key: 'email',
      },
      {
        title: 'Veiksmai',
        key: 'role',
        render (row, index) {
          return h(
            NButton,
            {
              strong: true,
              tertiary: true,
              size: 'small',
              onClick: () => editUser(row, index)
            },
            { default: () => '📝' }
          )
        }
      }
    ]

    const data = ref([])
    const loadingRef = ref(true)
    const paginationReactive = reactive({
      page: 1,
      pageCount: null,
      pageSize: null,
    })

    const handlePageChange = (currentPage) => {
      if (!loadingRef.value) {
        loadingRef.value = true
        const skipItems = (currentPage - 1) * paginationReactive.pageSize
        getAccounts({ skip: skipItems }).then(resp => {
          data.value = resp.data.data
          paginationReactive.page = currentPage
          paginationReactive.pageCount = Math.ceil(resp.data.total / paginationReactive.pageSize)
          loadingRef.value = false
        })
      }
    }

    // user edit
    const showModalRef = ref(false)
    const disableButtonRef = ref(false)
    const selectedUserDataRef = ref({})
    const editUser = (row, index) => {
      console.log(row, index)
      selectedUserDataRef.value = beforeEdit(row)
      showModalRef.value = true
    }

    const saveUserEdits = () => {
      disableButtonRef.value = true
      const userData_ = afterEdit(selectedUserDataRef.value)
      updateAccount(userData_.id, userData_).then(() => {
        message.success('Išsaugota')
        disableButtonRef.value = false
      })
    }

    const deleteUser = () => {
      disableButtonRef.value = true
      deleteAccount(selectedUserDataRef.value.id).then(() => {
        getAccounts({ skip: 0 }).then(resp => {
          data.value = resp.data.data
          paginationReactive.page = 1
          paginationReactive.pageSize = resp.data.data.length || 0
          paginationReactive.pageCount = Math.ceil(resp.data.total / paginationReactive.pageSize)
          loadingRef.value = false
        })
        message.success('Vartotojas pašalintas')
        disableButtonRef.value = false
      })
    }

    // search
    const searchFieldRef = ref('')

    const doSearch = () => {
      if (!loadingRef.value) {
        loadingRef.value = true
        getAccounts({ searchEmail: searchFieldRef.value }).then(resp => {
          data.value = resp.data.data
          paginationReactive.page = 1
          paginationReactive.pageCount = Math.ceil(resp.data.total / paginationReactive.pageSize)
          loadingRef.value = false
        })
      }
    }

    onMounted(() => {
      const skipItems = 0
      getAccounts({ skip: skipItems }).then(resp => {
        data.value = resp.data.data
        paginationReactive.page = 1
        paginationReactive.pageSize = resp.data.data.length || 0
        paginationReactive.pageCount = Math.ceil(resp.data.total / paginationReactive.pageSize)
        loadingRef.value = false
      })
    })

    return {
      columns,
      saveUserEdits,
      searchField: searchFieldRef,
      disableButton: disableButtonRef,
      deleteUser,
      doSearch,
      handlePageChange,
      loading: loadingRef,
      isMobile: useIsMobile(),
      data: data,
      pagination: paginationReactive,
      showModal: showModalRef,
      selectedUserData: selectedUserDataRef,
    }
  }
})
</script>

<style>
</style>
